
const HtmlHeader = ({ text, type }) => {

  switch (type) {
    case "h1":
      return (
        <h1 className={"header-top-margin"}>{text}</h1>
      );
    case "h2":
      return (
        <h2 className={"header-top-margin"}>{text}</h2>
      );
    case "h3":
      return (
        <h3 className={"header-top-margin"}>{text}</h3>
      );
    case "h4":
      return (
        <h4 className={"header-top-margin"}>{text}</h4>
      );
    default:
      return (
        <h3 className={"header-top-margin"}>{text}</h3>
      );
  }
}

export default HtmlHeader;