import {  useEffect } from 'react';
 import RelatedPages from '../../components/related-pages';
import { AbstractPage } from '../abstractPage';
import Courses from '../../components/courses';
import HtmlHeader from '../../components/html-header';
import Keywords from '../../components/keywords';
import LangSelect from '../../components/lang-select';

const CoursesPage = ({pageTitle, header, items, countryId, lastUpdated, keywords, keywordsAsString, relatedPages, langs}) => {

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (<AbstractPage >
    <LangSelect displayLangs={langs} />
    <HtmlHeader text={header} type="h1" />
    <Courses items={items ? items.filter(item => item.countries.includes(countryId)) :[]} lastUpdated={lastUpdated} />
    <Keywords items={keywords || keywordsAsString.split(',')} />
    {/* <RelatedPages list={relatedPages} /> */}
  </AbstractPage>);
}

export default CoursesPage;