
import { Route, Switch } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import MainPage from './pages';
import CoursesPage from './pages/courses';
import { countries, data } from './pages/data';
import { useIntl } from 'react-intl';
import './App.css';

function App() {
  const intl = useIntl();

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path={[
          "/:lang/georgia/vse-ayti-kursy-i-stajirovki",
          "/:lang/georgia/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.georgia.pageTitle" })}
            header={intl.formatMessage({ id: "app.georgia.header" })}
            items={data}
            countryId={countries.georgia}
            lastUpdated={"2024-09-10"}
            keywordsAsString={intl.formatMessage({ id: "app.georgia.keywordsAsString" })}
            relatedPages={[]}
            langs={['ge', 'ru', 'en']}
          ></CoursesPage>
        </Route>



        <Route exact path={[
          "/:lang/serbia/vse-ayti-kursy-i-stajirovki",
          "/:lang/serbia/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.serbia.pageTitle" })}
            header={intl.formatMessage({ id: "app.serbia.header" })}
            items={data}
            countryId={countries.serbia}
            lastUpdated={"2024-09-01"}
            keywordsAsString={intl.formatMessage({ id: "app.serbia.keywordsAsString" })}
            relatedPages={[]}
            langs={['ru', 'en']}
          ></CoursesPage>
        </Route>

        <Route exact path={[
          "/:lang/poland/vse-ayti-kursy-i-stajirovki",
          "/:lang/poland/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.poland.pageTitle" })}
            header={intl.formatMessage({ id: "app.poland.header" })}
            items={data}
            countryId={countries.poland}
            lastUpdated={"2024-09-03"}
            keywordsAsString={intl.formatMessage({ id: "app.poland.keywordsAsString" })}
            relatedPages={[]}
            langs={['pl', 'ru', 'en']}
          ></CoursesPage>
        </Route>

        <Route exact path={[
          "/:lang/bulgaria/vse-ayti-kursy-i-stajirovki",
          "/:lang/bulgaria/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.bulgaria.pageTitle" })}
            header={intl.formatMessage({ id: "app.bulgaria.header" })}
            items={data}
            countryId={countries.bulgaria}
            lastUpdated={"2024-09-06"}
            keywordsAsString={intl.formatMessage({ id: "app.bulgaria.keywordsAsString" })}
            relatedPages={[]}
            langs={['ru', 'en']}
          ></CoursesPage>
        </Route>


        <Route exact path={[
          "/:lang/armenia/vse-ayti-kursy-i-stajirovki",
          "/:lang/armenia/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.armenia.pageTitle" })}
            header={intl.formatMessage({ id: "app.armenia.header" })}
            items={data}
            countryId={countries.armenia}
            lastUpdated={"2024-09-06"}
            keywordsAsString={intl.formatMessage({ id: "app.armenia.keywordsAsString" })}
            relatedPages={[]}
            langs={['ru', 'en']}
          ></CoursesPage>
        </Route>

        <Route exact path={[
          "/:lang/kazakhstan/vse-ayti-kursy-i-stajirovki",
          "/:lang/kazakhstan/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.kazakhstan.pageTitle" })}
            header={intl.formatMessage({ id: "app.kazakhstan.header" })}
            items={data}
            countryId={countries.kazakhstan}
            lastUpdated={"2024-09-07"}
            keywordsAsString={intl.formatMessage({ id: "app.kazakhstan.keywordsAsString" })}
            relatedPages={[]}
            langs={['ru', 'en']}
          ></CoursesPage>
        </Route>



        {/* america */}

        <Route exact path={[
          "/:lang/argentina/todos-los-cursos-y-pasantias-de-ingenieria-de-software",
          "/:lang/argentina/all-courses-and-internships-for-software-engineers"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.argentina.pageTitle" })}
            header={intl.formatMessage({ id: "app.argentina.header" })}
            items={data}
            countryId={countries.argentina}
            lastUpdated={"2024-09-10"}
            keywordsAsString={intl.formatMessage({ id: "app.argentina.keywordsAsString" })}
            relatedPages={[]}
            langs={['es', 'en']}
          ></CoursesPage>
        </Route>


        {/* Asia */}

        <Route exact path={[
          "/:lang/uzbekistan/vse-ayti-kursy-i-stajirovki",
          "/:lang/uzbekistan/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.uzbekistan.pageTitle" })}
            header={intl.formatMessage({ id: "app.uzbekistan.header" })}
            items={data}
            countryId={countries.uzbekistan}
            lastUpdated={"2024-09-01"}
            keywordsAsString={intl.formatMessage({ id: "app.uzbekistan.keywordsAsString" })}
            relatedPages={[]}
            langs={['ru', 'en']}
          ></CoursesPage>
        </Route>

        <Route exact path={[
          "/:lang/kyrgyzstan/vse-ayti-kursy-i-stajirovki",
          "/:lang/kyrgyzstan/all-it-courses-and-internships"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.kyrgyzstan.pageTitle" })}
            header={intl.formatMessage({ id: "app.kyrgyzstan.header" })}
            items={data}
            countryId={countries.kyrgyzstan}
            lastUpdated={"2024-09-07"}
            keywordsAsString={intl.formatMessage({ id: "app.kyrgyzstan.keywordsAsString" })}
            relatedPages={[]}
            langs={['ru', 'en']}
          ></CoursesPage>
        </Route>

        <Route exact path={[
          "/:lang/argentina/todos-los-cursos-y-pasantias-de-ingenieria-de-software",
          "/:lang/argentina/all-courses-and-internships-for-software-engineers"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.argentina.pageTitle" })}
            header={intl.formatMessage({ id: "app.argentina.header" })}
            items={data}
            countryId={countries.argentina}
            lastUpdated={"2024-09-10"}
            keywordsAsString={intl.formatMessage({ id: "app.argentina.keywordsAsString" })}
            relatedPages={[]}
            langs={['es', 'en']}
          ></CoursesPage>
        </Route>

        <Route exact path={[
          "/:lang/vietnam/tất-cả-các-khóa-học-và-thực-tập-dành-cho-kỹ-sư-phần-mềm-và-người-kiểm-tra",
          "/:lang/vietnam/all-courses-and-internships-for-software-engineers"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.vietnam.pageTitle" })}
            header={intl.formatMessage({ id: "app.vietnam.header" })}
            items={data}
            countryId={countries.vietnam}
            lastUpdated={"2024-09-16"}
            keywordsAsString={intl.formatMessage({ id: "app.vietnam.keywordsAsString" })}
            relatedPages={[]}
            langs={['vi', 'en']}
          ></CoursesPage>
        </Route>


        {/* Africa */}

        <Route exact path={[
          "/:lang/egypt/ǧmī-al-dūrāt-al-tdrībīẗ-wāltdrīb-al-dāẖlī-lmhndsī-al-brmǧīāt",
          "/:lang/egypt/all-courses-and-internships-for-software-engineers"
        ]}>
          <CoursesPage
            pageTitle={intl.formatMessage({ id: "app.egypt.pageTitle" })}
            header={intl.formatMessage({ id: "app.egypt.header" })}
            items={data}
            countryId={countries.egypt}
            lastUpdated={"2024-09-17"}
            keywordsAsString={intl.formatMessage({ id: "app.egypt.keywordsAsString" })}
            relatedPages={[]}
            langs={['ar', 'en']}
          ></CoursesPage>
        </Route>


        <Route path="/">
          <MainPage pageTitle={intl.formatMessage({ id: "app.common.mainPageTitle" })} />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
