import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LangWrapper from './common/langWrapper';
import ruLocale from 'i18n-iso-countries/langs/ru.json'
import enLocale from 'i18n-iso-countries/langs/en.json'
import plLocale from 'i18n-iso-countries/langs/pl.json'
import geLocale from 'i18n-iso-countries/langs/ka.json'
import esLocale from 'i18n-iso-countries/langs/es.json'
import viLocale from 'i18n-iso-countries/langs/vi.json'
import arLocale from 'i18n-iso-countries/langs/ar.json'

// start: add styles for Ant design components
import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/alert/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/input-number/style/css';
import 'antd/lib/popover/style/css';
import 'antd/lib/menu/style/css';
import 'antd/lib/tabs/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/style/';
import './override-antd.css';
// end: add styles for Ant design components

import './index.scss';

dayjs.extend(customParseFormat);
dayjs.locale('ru');

// start: configure lib that provides translation of countries names
const countries = require("i18n-iso-countries");
countries.registerLocale(ruLocale)
countries.registerLocale(enLocale)
countries.registerLocale(plLocale)
countries.registerLocale(geLocale)
countries.registerLocale(esLocale)
countries.registerLocale(viLocale)
countries.registerLocale(arLocale)
// end: configure lib that provides translation of countries names


ReactDOM.render(
  <React.StrictMode>
    <LangWrapper>
      <Router>
        <App />
      </Router>
    </LangWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
