import { useIntl } from 'react-intl';
import "./index.css";

const Keywords = ({ items }) => {
  const intl = useIntl();

  return (
    <div className="container">
      <div className="keywords-container">
      {intl.formatMessage({ id: "app.common.keywords" })}: {items.map((value, index) => <span key={index} className='keywords-container__keyword'>{value}</span>)}
      </div>
    </div>
  );
}

export default Keywords;