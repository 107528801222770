import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Russian from './lang/ru.json';
import English from './lang/en.json';
import Polish from './lang/pl.json';
import Georgian from './lang/ge.json';
import Spanish from './lang/es.json';
import Vietnamese from './lang/vi.json';
import Arabic from './lang/ar.json';

export const Context = React.createContext();
const local = navigator.language;
let jsonDataForLanguage;
if (local === 'ru') {
    jsonDataForLanguage = Russian;
} else if (local === 'en') {
    jsonDataForLanguage = English;
} else if (local === 'pl') {
    jsonDataForLanguage = Polish;
} else if (local === 'ka') {
    jsonDataForLanguage = Georgian;
} else if (local === 'es') {
    jsonDataForLanguage = Spanish;
} else if (local === 'vi') {
    jsonDataForLanguage = Vietnamese;
} else if (local === 'ar') {
    jsonDataForLanguage = Arabic;
} else { // default
    jsonDataForLanguage = English;
}

const LangWrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(jsonDataForLanguage);
    function selectLanguage(newLocale) {
        setLocale(newLocale);
        if (newLocale === 'ru') {
            setMessages(Russian)
        } else if (newLocale === 'en') {
            setMessages(English)
        } else if (newLocale === 'pl') {
            setMessages(Polish)
        } else if (newLocale === 'ka') {
            setMessages(Georgian)
        } else if (newLocale === 'es') {
            setMessages(Spanish)
        } else if (newLocale === 'vi') {
            setMessages(Vietnamese)
        } else if (newLocale === 'ar') {
            setMessages(Arabic)
        } else { // default
            setMessages(English)
        }
    }
    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}
export default LangWrapper;