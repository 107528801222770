import { useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Context } from '../../common/langWrapper';
import "./index.css";

const updateCurrentUrlToLang = (url, newLang) => {
    // given: /ru/kyrgyzstan/vse-ayti-kursy-i-stajirovki
    const urlWithoutLang = url.slice(3);
    // now: /kyrgyzstan/vse-ayti-kursy-i-stajirovki
    return "/" + newLang + urlWithoutLang;
}

// used to handle cases when name of locale does not match name of the country
const localeMap = {
    "ge": "ka", // georgian
}

const LangSelect = ({ displayLangs }) => {

    const history = useHistory();
    const location = useLocation();
    const context = useContext(Context);
    const intl = useIntl();
    const { lang } = useParams();

    useEffect(() => {

        const internalLocaleName = localeMap[lang];

        if (internalLocaleName) {
            context.selectLanguage(internalLocaleName);
        } else {
            context.selectLanguage(lang);
        }

    }, [lang, context])


    const changeLocale = (e, locale) => {
        e.preventDefault();
        context.selectLanguage(locale);
        history.push(updateCurrentUrlToLang(location.pathname, locale));
    }

    return (
        <div className='lang-select-container'>
            <div>
                {intl.formatMessage({ id: "app.common.langSelect" })}:
                {displayLangs.includes('ru') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'ru')}
                        onClick={(e) => changeLocale(e, 'ru')}>Русский</a>
                }
                {displayLangs.includes('en') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'en')}
                        onClick={(e) => changeLocale(e, 'en')}>English</a>
                }
                {displayLangs.includes('pl') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'pl')}
                        onClick={(e) => changeLocale(e, 'pl')}>Polish</a>
                }
                {displayLangs.includes('ge') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'ge')}
                        onClick={(e) => changeLocale(e, 'ge')}>ქართული</a> // Geogrian
                }
                {displayLangs.includes('es') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'es')}
                        onClick={(e) => changeLocale(e, 'es')}>Spanish</a>
                }
                 {displayLangs.includes('vi') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'vi')}
                        onClick={(e) => changeLocale(e, 'vi')}>Tiếng Việt</a>
                }
                {displayLangs.includes('ar') &&
                    <a className="lang-select__change-lang-item"
                        href={updateCurrentUrlToLang(location.pathname, 'ar')}
                        onClick={(e) => changeLocale(e, 'ar')}>العربية</a>
                }
            </div>
        </div>
    );

}

export default LangSelect