import MenuMobile from './menu-mobile';
import { MenuDesktop } from './menu-desktop';
import { useContext } from 'react';
import { Context } from '../../common/langWrapper';
import './index.css';

var countries = require("i18n-iso-countries");

const Menu = () => {
  const context = useContext(Context);
  const locale = context.locale;

  const optionsRu = [
    { url: '/ru/georgia/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("GE", locale)}` },
    { url: '/ru/uzbekistan/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("UZ", locale)}` },
    { url: '/ru/serbia/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("RS", locale)}` },
    { url: '/ru/poland/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("PL", locale)}` },
    { url: '/ru/bulgaria/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("BG", locale)}` },
    { url: '/ru/armenia/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("AM", locale)}` },
    { url: '/ru/kazakhstan/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("KZ", locale)}` },
    { url: '/ru/kyrgyzstan/vse-ayti-kursy-i-stajirovki', label: `🔥${countries.getName("KG", locale)}` },
    { url: '/en/argentina/all-courses-and-internships-for-software-engineers', label: `🔥${countries.getName("AR", locale)}` },
    { url: '/en/vietnam/all-courses-and-internships-for-software-engineers', label: `🔥${countries.getName("VN", locale)}` },
    { url: '/en/egypt/all-courses-and-internships-for-software-engineers', label: `🔥${countries.getName("EG", locale)}` },
  ]

  const optionsEn = [
    { url: '/en/georgia/all-it-courses-and-internships', label: `🔥${countries.getName("GE", locale)}` },
    { url: '/en/uzbekistan/all-it-courses-and-internships', label: `🔥${countries.getName("UZ", locale)}` },
    { url: '/en/serbia/all-it-courses-and-internships', label: `🔥${countries.getName("RS", locale)}` },
    { url: '/en/poland/all-it-courses-and-internships', label: `🔥${countries.getName("PL", locale)}` },
    { url: '/en/bulgaria/all-it-courses-and-internships', label: `🔥${countries.getName("BG", locale)}` },
    { url: '/en/armenia/all-it-courses-and-internships', label: `🔥${countries.getName("AM", locale)}` },
    { url: '/en/kazakhstan/all-it-courses-and-internships', label: `🔥${countries.getName("KZ", locale)}` },
    { url: '/en/kyrgyzstan/all-it-courses-and-internships', label: `🔥${countries.getName("KG", locale)}` },
    { url: '/en/argentina/all-courses-and-internships-for-software-engineers', label: `🔥${countries.getName("AR", locale)}` },
    { url: '/en/vietnam/all-courses-and-internships-for-software-engineers', label: `🔥${countries.getName("VN", locale)}` },
    { url: '/en/egypt/all-courses-and-internships-for-software-engineers', label: `🔥${countries.getName("EG", locale)}` },
  ]

  let options;
  if (locale === 'ru') {
    options = optionsRu;
  } else {
    options = optionsEn;
  }

  return (
    <>
      <div className="menu__show-mobile-menu">
        <MenuMobile options={options} />
      </div>
      <div className="menu__show-desktop-menu">
        <MenuDesktop options={options} />
      </div>
    </>
  )
}

export default Menu;