export const countries = {
    "georgia": 1,
    "serbia": 2,
    "uzbekistan": 3,
    "poland": 4,
    "bulgaria": 5,
    "armenia": 6,
    "kazakhstan": 7,
    "kyrgyzstan": 8,
    "belarus": 9,
    "mexico": 10,
    "brazil": 11,
    "egypt": 12,
    "ukraine": 13,
    "russia": 14,
    "argentina": 15,
    "uruguay": 17,
    "chile": 18,
    "colombia": 19,
    "peru": 20,
    "vietnam": 21,
    "romania": 22,
    "lebanon": 23,

};

export const data = [
    {
        "title": "Cloud & DevOps Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4216",
        "registrationOpen": true,
        "countries": [countries.georgia]
    },
    {
        "title": "Cloud & DevOps Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4215",
        "registrationOpen": true,
        "countries": [countries.uzbekistan]
    },
    {
        "title": "QA Manual, PHP Internship",
        "shortDescription": "",
        "company": "Vention",
        "type": "internship",
        "link": "https://join.ventionteams.com/job-openings?levels=8&countries=5",
        "registrationOpen": true,
        "countries": [countries.uzbekistan]
    },
    {
        "title": "Data Science",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4184",
        "registrationOpen": true,
        "countries": [countries.georgia]
    },
    {
        "title": "Data Science",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4169",
        "registrationOpen": true,
        "countries": [countries.uzbekistan]
    },
    {
        "title": "JavaScript & Front-End Development",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4207",
        "registrationOpen": true,
        "countries": [countries.georgia, countries.uzbekistan, countries.armenia, countries.kazakhstan, countries.kyrgyzstan]
    },
    {
        "title": "Data Analytics Engineering",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4170",
        "registrationOpen": true,
        "countries": [countries.georgia]
    },
    {
        "title": "Data Analytics Engineering",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4166",
        "registrationOpen": true,
        "countries": [countries.uzbekistan]
    },
    {
        "title": "IT Operations and Support",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4210",
        "registrationOpen": true,
        "countries": [countries.georgia]
    },
    {
        "title": "Software Functional Testing Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4139",
        "registrationOpen": false,
        "countries": [countries.georgia]
    },
    {
        "title": "Software Functional Testing Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4141",
        "registrationOpen": false,
        "countries": [countries.uzbekistan]
    },
    {
        "title": "QA Functional testing",
        "shortDescription": "",
        "company": "a1qa",
        "type": "course",
        "link": "https://a1qa.ge/",
        "registrationOpen": true,
        "countries": [countries.georgia]
    },
    {
        "title": "QA Automation Engineer",
        "shortDescription": "",
        "company": "a1qa",
        "type": "course",
        "link": "https://a1qa.ge/",
        "registrationOpen": true,
        "countries": [countries.georgia]
    },
    {
        "title": "Software development: .NET, Java, Python, Ruby, PHP, JavaScript, iOS, DevOps and others",
        "shortDescription": "",
        "company": "Itransition",
        "type": "course",
        "link": "https://www.itransition.com/careers/uz/training",
        "registrationOpen": true,
        "countries": [countries.georgia, countries.uzbekistan]
    },
    {
        "title": "System Analysis for Beginners",
        "shortDescription": "",
        "company": "Andersen",
        "type": "course",
        "link": "https://people.andersenlab.com/ru/trainee",
        "registrationOpen": true,
        "countries": [countries.georgia, countries.serbia, countries.poland, countries.georgia, countries.kazakhstan, countries.belarus]
    },
    {
        "title": "Java for Beginners",
        "shortDescription": "",
        "company": "Andersen",
        "type": "course",
        "link": "https://people.andersenlab.com/ru/trainee",
        "registrationOpen": true,
        "countries": [countries.georgia, countries.serbia, countries.poland, countries.georgia, countries.kazakhstan, countries.belarus]
    },
    {
        "title": "UI/UX Design",
        "shortDescription": "",
        "company": "Andersen",
        "type": "course",
        "link": "https://people.andersenlab.com/ru/trainee",
        "registrationOpen": true,
        "countries": [countries.georgia, countries.serbia, countries.poland, countries.georgia,
        countries.kazakhstan, countries.belarus, countries.ukraine
        ]
    },
    {
        "title": "Quality Assurance for Beginners",
        "shortDescription": "",
        "company": "Andersen",
        "type": "course",
        "link": "https://people.andersenlab.com/ru/trainee",
        "registrationOpen": true,
        "countries": [countries.georgia, countries.serbia, countries.poland, countries.georgia, countries.kazakhstan, countries.belarus]
    },
    {
        "title": "Internship for Mobile, Design, JS, Java, Unity, Python and other techonologies",
        "shortDescription": "",
        "company": "Innowise",
        "type": "internship",
        "link": "https://jobs-innowise.com/en/internship/",
        "registrationOpen": false,
        "countries": [countries.georgia, countries.poland]
    },
    {
        "title": "Java, .NET и C++, frontend и mobile, BI и DevOps, QA automation и manual, бизнес-анализ",
        "shortDescription": "",
        "company": "Issoft",
        "type": "course",
        "link": "https://issoft.ge/ru/home-ru/growth-ru/",
        "registrationOpen": false,
        "countries": [countries.georgia]
    },

    {
        "title": "Java, Python, .NET, Node, QA manual/automation, Big Data, iOS, Android, DevOps internships and more",
        "shortDescription": "",
        "company": "GridDynamics",
        "type": "internship",
        "link": "https://portal.griddynamics.net/internship/serbia",
        "registrationOpen": true,
        "countries": [countries.serbia]
    },
    {
        "title": "Cloud & DevOps Mentoring Program",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4202",
        "registrationOpen": true,
        "countries": [countries.serbia]
    },
    {
        "title": "Cloud and DevOps Fundamentals",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4182",
        "registrationOpen": true,
        "countries": [countries.serbia]
    },
    {
        "title": "Data Science",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4162",
        "registrationOpen": true,
        "countries": [countries.serbia]
    },
    {
        "title": "Data Analytics Engineering",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4164",
        "registrationOpen": true,
        "countries": [countries.serbia]
    },





    {
        "title": "Data Science",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4158",
        "registrationOpen": true,
        "countries": [countries.poland]
    },

    {
        "title": "Java Fundamentals",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4188",
        "registrationOpen": true,
        "countries": [countries.poland]
    },
    {
        "title": "Data Analytics Engineering",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4157",
        "registrationOpen": true,
        "countries": [countries.poland]
    },
    {
        "title": "Cloud & DevOps Fundamentals",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4181",
        "registrationOpen": true,
        "countries": [countries.poland]
    },

    {
        "title": ".NET Online Program",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4212",
        "registrationOpen": true,
        "countries": [countries.poland]
    },


    {
        "title": "Test automation engineer, C++/Golang software engineer, HR, etc.",
        "shortDescription": "",
        "company": "Softserve",
        "type": "trainee",
        "link": "https://career.softserveinc.com/en-us/vacancies/country-poland/position-junior,trainee",
        "registrationOpen": false,
        "countries": [countries.poland]
    },



    {
        "title": "Tech internships for developers, QAs, etc.",
        "shortDescription": "",
        "company": "UBS",
        "type": "internship",
        "link": "https://jobs.ubs.com/TGnewUI/Search/Home/HomeWithPreLoad?partnerid=25008&siteid=5012&PageType=searchResults&SearchType=linkquery&LinkID=3578#keyWordSearch=&locationSearch=&Function%20Category=Information%20Technology%20(IT)",
        "registrationOpen": true,
        "countries": [countries.poland]
    },

    {
        "title": "UI/UX, Frontend (React), Java, QA, Node.js, .NET, etc.",
        "shortDescription": "",
        "company": "Sigma Software",
        "type": "",
        "link": "https://career.sigma.software/what-we-offer/internships/",
        "registrationOpen": false,
        "countries": [countries.poland, countries.bulgaria, countries.mexico, countries.brazil]
    },

    {
        "title": "Tech internships cloud support engineers, content build specialists, etc.",
        "shortDescription": "",
        "company": "SAP",
        "type": "internship",
        "link": "https://jobs.sap.com/search/?createNewAlert=false&q=internship&locationsearch=&optionsFacetsDD_department=&optionsFacetsDD_customfield3=&optionsFacetsDD_country=PL",
        "registrationOpen": true,
        "countries": [countries.poland]
    },
    {
        "title": "Internships for tech and product support positions",
        "shortDescription": "",
        "company": "Kyriba",
        "type": "internship",
        "link": "https://kyriba.wd5.myworkdayjobs.com/Kyriba-Careers?q=intern&locationCountry=131d5ac7e3ee4d7b962bdc96e498e412",
        "registrationOpen": true,
        "countries": [countries.poland]
    },
    {
        "title": "Internships in Dassault Systèmes",
        "shortDescription": "",
        "company": "Dassault Systèmes",
        "type": "internship",
        "link": "https://www.3ds.com/careers/jobs?woc=%257B%2522type%2522%253A%255B%2522type%252FInternship%2522%255D%252C%2522country%2522%253A%255B%2522country%252FPoland%2522%255D%257D",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "Internships in Philips",
        "shortDescription": "",
        "company": "Philips ",
        "type": "internships",
        "link": "https://www.careers.philips.com/cee/en/search-results?from=10&s=1",
        "registrationOpen": false,
        "countries": [countries.poland]
    },

    {
        "title": "Tech internships in IBM",
        "shortDescription": "",
        "company": "IBM",
        "type": "internships",
        "link": "https://www.ibm.com/uk-en/careers/search?field_keyword_18[0]=Intern&field_keyword_05[0]=Poland",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "Software development: .NET, Java, Python, Ruby, PHP, JavaScript, iOS, DevOps and others",
        "shortDescription": "",
        "company": "Itransition",
        "type": "course",
        "link": "https://www.itransition.com/careers/training",
        "registrationOpen": true,
        "countries": [countries.poland]
    },
    {
        "title": "Software engineering internships (.NET, Mobile, DevOps, Salesforce, QA, etc.)",
        "shortDescription": "",
        "company": "Coherent Solutions",
        "type": "",
        "link": "https://www.coherentsolutions.com/careers",
        "registrationOpen": false,
        "countries": [countries.poland, countries.bulgaria, countries.georgia, countries.mexico]
    },




    {
        "title": "Software engineering internships (.NET, Mobile, DevOps, Salesforce, QA, etc.)",
        "shortDescription": "",
        "company": "Britenet",
        "type": "",
        "link": "https://britenet.eu/career/",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": ".NET, QA, C++, DevOps, Golang, Java, JavaScript, Node.js, PHP, Python, etc.",
        "shortDescription": "",
        "company": "Avenga",
        "type": "",
        "link": "https://www.avenga.com/career/vacancies-poland/?vacancy_level%5B%5D=981&vacancy_level%5B%5D=979&vacancy_level%5B%5D=978",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": ".NET, C++, Data engineering, Mobile, etc.",
        "shortDescription": "",
        "company": "Scalo",
        "type": "",
        "link": "https://www.career.scalosoft.com/oferty-pracy/java/",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "Java, QA",
        "shortDescription": "",
        "company": "Software Mind",
        "type": "internship",
        "link": "https://careers.softwaremind.com/?s=intern&post_type=jobs#page=1&searchTerm=intern&selectedParentCategory=_&selectedChildCategories=&selectedLocations=&selectedTypeOfEmployment=",
        "registrationOpen": false,
        "countries": [countries.poland]
    },




    {
        "title": "Java, QA, Node.js, .NET, Python, Mobile, etc.",
        "shortDescription": "",
        "company": "BlueSoft",
        "type": "",
        "link": "https://bluesoft.com/careers/job-offers/?categories%5B%5D=217&search=",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "Test (QA) automation",
        "shortDescription": "",
        "company": "Transition Technologies PSC",
        "type": "internship",
        "link": "https://kariera.ttpsc.com/en/job-offers/",
        "registrationOpen": true,
        "countries": [countries.poland]
    },
    {
        "title": "Node.js, Front-end SAPUI5 framework, SAP ABAP",
        "shortDescription": "",
        "company": "LeverX",
        "type": "course",
        "link": "https://career.leverx.com/courses",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.uzbekistan, countries.bulgaria, countries.georgia, countries.poland]
    },
    {
        "title": "SAP, Java, ABAP, Python, Typescript",
        "shortDescription": "",
        "company": "LeverX",
        "type": "internship",
        "link": "https://career.leverx.com/internship",
        "registrationOpen": false,
        "countries": [countries.kazakhstan, countries.uzbekistan, countries.bulgaria, countries.georgia, countries.poland]
    },
    {
        "title": "Java, .NET, C++, JavaScript, Angular, DevOps",
        "shortDescription": "",
        "company": "Sii Poland",
        "type": "internship",
        "link": " https://sii.pl/en/job-ads/poland/all/all/all/intern/",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "Different tech and non-tech positions",
        "shortDescription": "",
        "company": "GFT Poland",
        "type": "internship",
        "link": "https://jobs.gft.com/Poland/go/poland/4413301/?createNewAlert=false&q=intern&locationsearch=&optionsFacetsDD_country=PL&optionsFacetsDD_customfield1=&optionsFacetsDD_shifttype=",
        "registrationOpen": false,
        "countries": [countries.poland]
    },


    {
        "title": "Java, QA, QA Automation, Customer support, etc.",
        "shortDescription": "",
        "company": "Eleks",
        "type": "",
        "link": "https://careers.eleks.com/vacancies/?location=31&technology=&query=intern",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "QA, QA Automation, Python, C++",
        "shortDescription": "",
        "company": "Luxoft",
        "type": "internship",
        "link": "https://career.luxoft.com/jobs?keyword=intern",
        "registrationOpen": false,
        "countries": [countries.poland]
    },
    {
        "title": "Python, C++, QA, etc.",
        "shortDescription": "",
        "company": "Luxoft",
        "type": "internship",
        "link": "https://career.luxoft.com/jobs?keyword=intern&country[]=Egypt",
        "registrationOpen": true,
        "countries": [countries.egypt]
    },




    {
        "title": "TEST AUTOMATION PROJECT LAB",
        "shortDescription": "",
        "company": "Softserve",
        "type": "course",
        "link": "https://career.softserveinc.com/en-us/technology/course/test-automation-project-lab",
        "registrationOpen": true,
        "countries": [countries.bulgaria]
    },

    {
        "title": "PYTHON PROJECT LAB",
        "shortDescription": "",
        "company": "Softserve",
        "type": "course",
        "link": "https://career.softserveinc.com/en-us/technology/course/python-project-lab",
        "registrationOpen": true,
        "countries": [countries.bulgaria]
    },


    // add Armenia
    {
        "title": "Cloud & DevOps Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4208",
        "registrationOpen": true,
        "countries": [countries.armenia]
    },
    {
        "title": "Data Science",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4186",
        "registrationOpen": true,
        "countries": [countries.armenia]
    },
    {
        "title": "Data Analytics Engineering",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4167",
        "registrationOpen": true,
        "countries": [countries.armenia]
    },
    {
        "title": "IT Operations and Support",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4204",
        "registrationOpen": true,
        "countries": [countries.armenia]
    },


    {
        "title": "Embedded/Frontend/Fullstack software engineer, CAD engineer",
        "shortDescription": "",
        "company": "Synopsys",
        "type": "internship",
        "link": "https://careers.synopsys.com/search-jobs/Hire%20Type%3A%20Intern/Armenia/44408/1/2/174982/40x25/45/50/2",
        "registrationOpen": false,
        "countries": [countries.armenia]
    },
    {
        "title": "ML/Data/Java engineers",
        "shortDescription": "",
        "company": "Polixis",
        "type": "internship",
        "link": "https://polixis.com/careers/",
        "registrationOpen": false,
        "countries": [countries.armenia]
    },


    {
        "title": "Course for System/Embedded engineers",
        "shortDescription": "",
        "company": "RAFA Solutions",
        "type": "course",
        "link": "https://rafasolutions.com/careers/trainings/",
        "registrationOpen": true,
        "countries": [countries.armenia]
    },
    {
        "title": "Internship for System/Embedded engineers",
        "shortDescription": "",
        "company": "RAFA Solutions",
        "type": "internship",
        "link": "https://rafasolutions.com/careers/internships/",
        "registrationOpen": false,
        "countries": [countries.armenia]
    },

    {
        "title": "JetBrains Internships",
        "shortDescription": "",
        "company": "JetBrains",
        "type": "internship",
        "link": "https://internship.jetbrains.com/",
        "registrationOpen": false,
        "countries": [countries.armenia, countries.serbia, countries.poland]
    },

    {
        "title": "Java, DevOps, UI, Business/System Analyst",
        "shortDescription": "",
        "company": "GridDynamics",
        "type": "internship",
        "link": "https://portal.griddynamics.net/internship/armenia",
        "registrationOpen": true,
        "countries": [countries.armenia]
    },
    {
        "title": "Training programs (C#, Python, QA, UX/UI, Project management)",
        "shortDescription": "",
        "company": "Microsoft Innovation Center Armenia",
        "type": "course",
        "link": "https://mic.am/training-program/",
        "registrationOpen": false,
        "countries": [countries.armenia]
    },
    {
        "title": "Cloud & DevOps Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4213",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "Data Science",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4165",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.kyrgyzstan]
    },
    {
        "title": "IT Operations and Support",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4209",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "Data Analytics Engineering",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4168",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.kyrgyzstan]
    },
    {
        "title": "Software Functional Testing Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4040",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },
    {
        "title": ".NET Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/3961",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.kyrgyzstan]
    },
    {
        "title": "Cloud and DevOps Fundamentals",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/3585",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.kyrgyzstan, countries.uzbekistan]
    },
    {
        "title": "Front-end, back-end, full-stack, android, ios",
        "shortDescription": "",
        "company": "Crystal Spring",
        "type": "internship",
        "link": "https://crystalspring.kz/vakansii/stazhirovka-dlya-molodyh-speczialistov/",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },


    {
        "title": "Java, .NET, iOS, Аналитик",
        "shortDescription": "",
        "company": "НАТ Казахстан",
        "type": "internship",
        "link": "https://nat.kz/career#!/tab/447257614-3",
        "registrationOpen": false,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "QA Intern - Functional/Automation testing",
        "shortDescription": "",
        "company": "a1qa",
        "type": "internship",
        "link": "https://career.a1qa.kz/",
        "registrationOpen": false,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "Internships for frontend, backend, etc.",
        "shortDescription": "",
        "company": "Vention",
        "type": "internship",
        "link": "https://join.ventionteams.com/job-openings?countries=10&levels=8",
        "registrationOpen": false,
        "countries": [countries.kazakhstan]
    },



    {
        "title": "Google Developer Groups | Cloud Almaty",
        "shortDescription": "",
        "company": "GDG Cloud Almaty",
        "type": "course",
        "link": "https://gdg.community.dev/gdg-cloud-almaty/",
        "registrationOpen": false,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "Learn Google Cloud / Generative AI -> receive badges and internships! ",
        "shortDescription": "",
        "company": "Google Cloud",
        "type": "course",
        "link": "https://rsvp.withgoogle.com/events/google_cloud_ml_learning_ca",
        "registrationOpen": false,
        "countries": [countries.kyrgyzstan, countries.kazakhstan, countries.uzbekistan]
    },

    {
        "title": "Datacom, Wireless, Core Network, RF Engineering, OWS Engineering, Smartcare Engineering",
        "shortDescription": "",
        "company": "Huawei",
        "type": "internship",
        "link": "https://www.huawei.com/uz/careers",
        "registrationOpen": false,
        "countries": [countries.uzbekistan]
    },
    {
        "title": "Internship for developers and QAs",
        "shortDescription": "",
        "company": "DataArt",
        "type": "internship",
        "link": "https://www.dataart.team/career/for-trainee",
        "registrationOpen": true,
        "countries": [countries.serbia, countries.poland, countries.ukraine, countries.armenia, countries.bulgaria]
    },
    {
        "title": "iOS, Data Analytics, PHP, etc.",
        "shortDescription": "",
        "company": "Kolesa Group",
        "type": "internship",
        "link": "https://kolesa.group/education/academy#internships",
        "registrationOpen": false,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "iOS, Android, UX/UI, Backend(Python), etc.",
        "shortDescription": "",
        "company": "Kcell",
        "type": "internship",
        "link": "https://jobs.kcell.kz/",
        "registrationOpen": false,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "Специалист по работе с Colvir (PL/SQL)",
        "shortDescription": "",
        "company": "Bank CenterCredit",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/bank-centercredit_%D0%B4%D1%80%D1%83%D0%B7%D1%8C%D1%8F-%D0%BC%D1%8B-%D0%B7%D0%B0%D0%BF%D1%83%D1%81%D0%BA%D0%B0%D0%B5%D0%BC-it-%D1%88%D0%BA%D0%BE%D0%BB%D1%83-bcc-%D0%B8%D1%89%D0%B5%D0%BC-activity-7061979873996615680-lfJ3/?utm_source=share&utm_medium=member_desktop",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },
    {
        "title": "Летняя школа по iOS, Android и Flutter, бэкенд-разработке на Python, Java, C++ и Go, менеджменту, UX/UI",
        "shortDescription": "",
        "company": "Академия Яндекса",
        "type": "course",
        "link": "https://yandex.ru/yaintern/schools/summer",
        "registrationOpen": false,
        "countries": [countries.russia, countries.kazakhstan, countries.serbia]
    },
    {
        "title": "iOS, PHP, Angular, Android, Java, React",
        "shortDescription": "",
        "company": "ONE technologies",
        "type": "internship",
        "link": "https://lab.one.kz",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },

    {
        "title": "iOS, PHP, Angular, Android, Java, React, QA, etc.",
        "shortDescription": "",
        "company": "red_mad_robot",
        "type": "internship",
        "link": "https://upgrade.redmadrobot.com/bootcamp",
        "registrationOpen": false,
        "countries": [countries.kazakhstan, countries.russia]
    },

    {
        "title": "Backend/Frontend/Mobile разработка, Data science, UX/UI дизайн, Тестирование, Поддержка, Аналитика, Информационная безопасность и др.",
        "shortDescription": "",
        "company": "Halyk Bank",
        "type": "internship",
        "link": "https://halykbank.kz/about/career/halyk-start",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.russia]
    },
    {
        "title": "Т‑Банк Старт: Разработка, Аналитика, QA, Маркетинг, Менеджмент, Финансы, Юриспруденция",
        "shortDescription": "",
        "company": "Т‑Банк",
        "type": "internship",
        "link": "https://education.tbank.ru/start/",
        "registrationOpen": true,
        "countries": [countries.kazakhstan, countries.russia, countries.belarus]
    },
    {
        "title": "Kaspi Lab: Разработка, Анализ данных, Дизайн, Тестирование, Администрирование БД",
        "shortDescription": "",
        "company": "Kaspi",
        "type": "internship",
        "link": "https://lab.kaspi.kz/",
        "registrationOpen": true,
        "countries": [countries.kazakhstan]
    },




    {
        "title": "Intern Labs: Java Backend Developer и QA Automation",
        "shortDescription": "",
        "company": "O!",
        "type": "internship",
        "link": "https://internlabs.o.kg/",
        "registrationOpen": true,
        "countries": [countries.kyrgyzstan]
    },
    {
        "title": "Разработка, проект-менеджмент, тестирование, бизнес аналитика, системное администрирование",
        "shortDescription": "",
        "company": "MyCloud.kg",
        "type": "internship",
        "link": "https://www.mycloud.kg/internship",
        "registrationOpen": false,
        "countries": [countries.kyrgyzstan]
    },
    {
        "title": "Мобильная разработка (Android, iOS), backend разработка",
        "shortDescription": "",
        "company": "Beeline",
        "type": "internship",
        "link": "https://beeline.kg/ru/stajirovka",
        "registrationOpen": true,
        "countries": [countries.kyrgyzstan]
    },


    {
        "title": "Cloud & DevOps Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4214",
        "registrationOpen": true,
        "countries": [countries.kyrgyzstan]
    },
    {
        "title": "IT Operations and Support",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4211",
        "registrationOpen": true,
        "countries": [countries.kyrgyzstan]
    },
    {
        "title": "Software Functional Testing Internship",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4142",
        "registrationOpen": false,
        "countries": [countries.kyrgyzstan]
    },
    {
        "title": "Backend-разработка, Frontend-разработка, Database, Тестирование",
        "shortDescription": "",
        "company": "TimelySkills",
        "type": "internship",
        "link": "https://timelyskills.com/",
        "registrationOpen": false,
        "countries": [countries.kyrgyzstan]
    },


    {
        "title": "Cloud Platform Engineer Lab (extended program)",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4221",
        "registrationOpen": true,
        "countries": [countries.argentina, countries.mexico]
    },

    {
        "title": "Cloud&DevOps Fundamentals, Part 1",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4222",
        "registrationOpen": true,
        "countries": [countries.argentina, countries.mexico]
    },
    {
        "title": "Cloud&DevOps Fundamentals, Part 2",
        "shortDescription": "",
        "company": "EPAM",
        "type": "course",
        "link": "https://training.epam.com/en/training/4224",
        "registrationOpen": true,
        "countries": [countries.argentina, countries.mexico]
    },
    {
        "title": "Cloud Platform Engineer Lab",
        "shortDescription": "",
        "company": "EPAM",
        "type": "internship",
        "link": "https://training.epam.com/en/training/4198",
        "registrationOpen": false,
        "countries": [countries.argentina, countries.mexico]
    },

    {
        "title": "QA/manual software testing",
        "shortDescription": "",
        "company": "Rootstrap",
        "type": "internship",
        "link": "https://www.rootstrap.com/blog/how-a-qa-internship-at-rootstrap-changed-my-life",
        "registrationOpen": false,
        "countries": [countries.argentina]
    },
    {
        "title": "QA/manual software testing",
        "shortDescription": "",
        "company": "Facultad de Humanidades",
        "type": "course",
        "link": "https://www.ces.com.uy/capacitacion/carrera-de-testing/",
        "registrationOpen": false,
        "countries": [countries.uruguay]
    },
    {
        "title": "Quality Assurance/QA/Manual QA",
        "shortDescription": "",
        "company": "Solvd",
        "type": "course",
        "link": "https://laba.solvd.com/manual-qa",
        "registrationOpen": false,
        "countries": [countries.argentina]
    },

    {
        "title": "Java Test Automation Course / QA automation",
        "shortDescription": "",
        "company": "Solvd",
        "type": "course",
        "link": "https://laba.solvd.com/test-automation",
        "registrationOpen": true,
        "countries": [countries.poland]
    },

    {
        "title": "React.js Development. Free Online Course",
        "shortDescription": "",
        "company": "Solvd",
        "type": "course",
        "link": "https://laba.solvd.com/reactjs",
        "registrationOpen": false,
        "countries": [countries.argentina]
    },
    {
        "title": "Node.js Development. Free Online Course",
        "shortDescription": "",
        "company": "Solvd",
        "type": "course",
        "link": "https://laba.solvd.com/nodejs",
        "registrationOpen": false,
        "countries": [countries.argentina]
    },
    {
        "title": "Intern Program (Cloud), etc.",
        "shortDescription": "",
        "company": "IBM",
        "type": "internship",
        "link": "https://www.ibm.com/careers/search?field_keyword_18[0]=Intern&field_keyword_05[0]=Argentina",
        "registrationOpen": false,
        "countries": [countries.argentina]
    },
    {
        "title": "Intern QA Automation Engineer",
        "shortDescription": "",
        "company": "Automate The Planet",
        "type": "internship",
        "link": "https://www.automatetheplanet.com/careers/intern-qa-automation-engineer/",
        "registrationOpen": false,
        "countries": [countries.bulgaria]
    },
    {
        "title": "Internship Experience Program (iXp)",
        "shortDescription": "",
        "company": "SAP",
        "type": "internship",
        "link": "https://jobs.sap.com/content/internships/",
        "registrationOpen": true,
        "countries": [countries.argentina, countries.brazil, countries.bulgaria,
        countries.chile, countries.colombia, countries.peru]
    },
    {
        "title": "Accenture Internship Program",
        "shortDescription": "",
        "company": "Accenture",
        "type": "internship",
        "link": "  https://www.accenture.com/sg-en/careers/local/accenture-internship-program",
        "registrationOpen": false,
        "countries": [countries.argentina, countries.brazil, countries.bulgaria,
        countries.chile, countries.colombia, countries.peru]
    },
    {
        "title": "Software Engineer Internship",
        "shortDescription": "",
        "company": "JPMorganChase",
        "type": "internship",
        "link": "  https://careers.jpmorgan.com/global/en/students/programs/software-engineer-summer",
        "registrationOpen": false,
        "countries": [countries.argentina, countries.brazil, countries.bulgaria,
        countries.chile, countries.colombia, countries.peru]
    },
    {
        "title": "Web Developer (PHP Laravel, ReactJs, NodeJs)",
        "shortDescription": "",
        "company": "",
        "type": "internship",
        "link": "https://fabatechnology.com/careers/web-developer-internship",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Test (QA) engineer, DevOps engineer",
        "shortDescription": "",
        "company": "Kms Technology",
        "type": "internship",
        "link": "https://careers.kms-technology.com/job/?location=Ho%20Chi%20Minh%2CDa%20Nang%2CHanoi&jobs_type=Fresher",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "AI/Machine learning",
        "shortDescription": "",
        "company": "InApps Technology",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/inapps-technology-co_what-an-internship-at-inapps-be-like-activity-6968873453714116608-8CMK/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "React/Typescript/Shopify/System engineers",
        "shortDescription": "",
        "company": "Xenia Tech",
        "type": "internship",
        "link": "https://xenia.tech/jobs",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Software Development, Management, Product Development",
        "shortDescription": "",
        "company": "LOGIX",
        "type": "internship",
        "link": "https://careers.logixtek.com/jobs/?search_keywords=intern&search_category=All+Categories&search_region=All+Regions",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Tester (manual/automation), backend/frontend/mobile/embedded developers, Data science/AI/Machine learning, UX/UI designer, Marketing/Business Analyst",
        "shortDescription": "",
        "company": "TMA Solutions",
        "type": "internship",
        "link": "https://feee.tdtu.edu.vn/en/news/recruiments-scholarships/applying-internship-tma-solutions",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },

    {
        "title": "Developers (Python, React Native, PHP), Automation testers, Business Analyst",
        "shortDescription": "",
        "company": "Bac Ha Software",
        "type": "internship",
        "link": "https://int.bachasoftware.com/jobs?search=trainee&order=name+asc",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Testers (manual/automation), Process Quality Assurance",
        "shortDescription": "",
        "company": "SmartOSC",
        "type": "internship",
        "link": "https://careers.smartosc.com/en/job-type/fresh-graduate/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": ".NET/Cloud engineer, HR, Marketing/Marketing analyst",
        "shortDescription": "",
        "company": "Rikkeisoft",
        "type": "internship",
        "link": " https://tuyendung.rikkeisoft.com/recruitment/list-job",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Software Engineering Intern (AI/ML, Android, Javascript, Typescript, NodeJS, Java/Spring, Big data, ReactJS, Python/Flask",
        "shortDescription": "",
        "company": "FPT Software",
        "type": "internship",
        "link": " https://fptsoftware.com/pages/global-internship",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },

    {
        "title": "AI/ML engineer, Data engineer, etc.",
        "shortDescription": "",
        "company": "Orient Software",
        "type": "internship",
        "link": " https://www.orientsoftware.com/news/a-year-of-blooming-training-activies/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "AI/ML engineer, Business analyst, QA enineer, etc.",
        "shortDescription": "",
        "company": "Invest Idea",
        "type": "internship",
        "link": " https://investidea.tech/blog/tag/career/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Frontend/backend/data/ML/QA/QA automation, etc.",
        "shortDescription": "",
        "company": "HCLTech",
        "type": "internship",
        "link": " https://www.hcltech.com/en-vn/careers/graduates",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Graduate Program: Quality engineering, software engineering, consulting, sales, manufacturing operations, project engineering, and many more.",
        "shortDescription": "",
        "company": "Hitachi Energy",
        "type": "internship",
        "link": "https://www.hitachienergy.com/careers/about-us/early-career#students-and-graduates",
        "registrationOpen": false,
        "countries": [countries.vietnam, countries.brazil, countries.poland]
    },
    {
        "title": "Software Testing Engineers, .NET Engineers, JAVA Engineers, NodeJS Engineers, Business Analysts",
        "shortDescription": "",
        "company": "NashTech",
        "type": "internship",
        "link": " https://careers.nashtechglobal.com/fresher-program/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Data engineers, Scala engineers, iOS/Android engineers, Site Reliability Engineering",
        "shortDescription": "",
        "company": "Wizeline",
        "type": "bootcamp",
        "link": "https://academy.wizeline.com/bootcamps/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },

    {
        "title": "PentaStagiu: Java, Angular, React, QA",
        "shortDescription": "",
        "company": "Pentalog",
        "type": "bootcamp",
        "link": "https://digital-platform.pentalog.com/pentastagiu-brasov-registration.html",
        "registrationOpen": false,
        "countries": [countries.romania]
    },
    {
        "title": "Flutter/React Native/Android/iOS/Testers(QA)",
        "shortDescription": "",
        "company": "NEWWAVE Solutions",
        "type": "internship",
        "link": "https://tuyendung.newwave.vn/recruit",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "GEM Internship Program",
        "shortDescription": "",
        "company": "GEM Corporation",
        "": "https://www.facebook.com/GEMinternshipprogram",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "PM, DevOps, Golang, ReactJS NodeJS, and Java",
        "shortDescription": "",
        "company": "VMO Holdings",
        "link": "https://www.linkedin.com/jobs/view/developer-internship-at-vmo-holdings-3988676571",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Trainings on software testing, PHP, Java",
        "shortDescription": "",
        "company": "CO-WELL Asia",
        "link": "https://co-well.vn/en/careers/training-courses/",
        "type": "course",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "IT internship program for students",
        "shortDescription": "",
        "company": "CO-WELL Asia",
        "link": "https://co-well.vn/en/careers/training-courses/",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },

    {
        "title": "Golang developer Intern, Talent Acquisition Intern, Scrum master/Project manager Intern, etc.",
        "shortDescription": "",
        "company": "Techvify",
        "link": "https://careers.techvify.com.vn/intern-fresher-program/",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "SmartDev Summer Internship (ReactJs, Java, VueJs)",
        "shortDescription": "",
        "company": "SmartDev",
        "link": "https://www.smartdev.com/summer-internship-program-2021/",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Testers, Web developers, .Net developers, etc.",
        "shortDescription": "",
        "company": "Kaopiz",
        "link": "https://tuyendung.kaopiz.com/jobs#job",
        "type": "internship",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Developers(Java), Testers, DevOps",
        "shortDescription": "",
        "company": "Axon Active",
        "link": "https://www.edu.axonactive.com/",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Fresher Training Program 2024 – Fullstack Software Engineer",
        "shortDescription": "",
        "company": "AGEST",
        "link": "https://www.agest.vn/hiring/full-stack-engineer-fresher/",
        "type": "internship",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Internship Program: Quality Control, Business Analyst",
        "shortDescription": "",
        "company": "Dirox",
        "link": "https://dirox.com/careers",
        "type": "internship",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Frontend(React, Nextjs), SAP ABAP, Business Analyst",
        "shortDescription": "",
        "company": "Sphinx",
        "link": "https://sphinxjsc.com/career/",
        "type": "internship",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "QA, Mobile/Web development",
        "shortDescription": "",
        "company": "Vinova",
        "link": "https://www.linkedin.com/posts/vinova-sg_internships-career-opportunities-activity-7068803506253557760-TOCK/",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "HR Intern",
        "shortDescription": "",
        "company": "Enosta",
        "link": "https://enosta.com/career/hr-intern/",
        "type": "internship",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "QC, Android, iOS and BA",
        "shortDescription": "",
        "company": "SupremeTech",
        "type": "internship",
        "link": "https://www.supremetech.vn/supremetech-internship-2022/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "React, Node.js developers",
        "shortDescription": "",
        "company": "Designveloper",
        "type": "internship",
        "link": "https://www.designveloper.com/blog/dsv-internship-program-2022/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": ".NET Full Stack development, PHP Full Stack development, ",
        "shortDescription": "",
        "company": "Integrated Digital Systems - IDS",
        "type": "internship",
        "link": "https://academy.ids.com.lb/Our-Programs",
        "registrationOpen": true,
        "countries": [countries.lebanon]
    },


    
    {
        "title": "Internship Programs - FrontEnd (Angular, VueJS, and ReactJS)",
        "shortDescription": "",
        "company": "NCCPLUS Vietnam JSC",
        "type": "internship",
        "link": "https://career.ncc.asia/internship-programs-frontend/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Internship Programs - NodeJS",
        "shortDescription": "",
        "company": "NCCPLUS Vietnam JSC",
        "type": "internship",
        "link": "https://career.ncc.asia/internship-programs-nodejs/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "BEETECH ACADEMY: (PHP&laravel, Java&Spring boot, iOS/Android)",
        "shortDescription": "",
        "company": "BEETECH",
        "type": "internship",
        "link": "https://beetechsoft.com/academy",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "IT Support, Recruitment, Graphic Design, WordPress Developer, Business Analyst internships",
        "shortDescription": "",
        "company": "TRG International",
        "type": "internship",
        "link": "https://careers.trginternational.com/pages/internships-in-vietnam-2021",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Human Resources",
        "shortDescription": "",
        "company": "Tan Chong Motor Holdings Berhad",
        "type": "internship",
        "link": "https://www.tanchonggroup.com/careers/internship/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Software Development Intern",
        "shortDescription": "",
        "company": "ITC Group",
        "type": "internship",
        "link": "https://itcgroup.io/careers/software-development-intern/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Software Tester Intern",
        "shortDescription": "",
        "company": "ITC Group",
        "type": "internship",
        "link": "https://itcgroup.io/careers/software-tester-intern/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "Business Analyst Intern",
        "shortDescription": "",
        "company": "ITC Group",
        "type": "internship",
        "link": "https://itcgroup.io/careers/ba-intern/",
        "registrationOpen": true,
        "countries": [countries.vietnam]
    },
    {
        "title": "INTERN TESTER TRAINING PROGRAM",
        "shortDescription": "",
        "company": "Icetea Software",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/iceteasoftware_iceteasoftware-intern-tester-activity-7219908755952431104-NqXN/",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },


    
    {
        "title": "Flutter/React Native, ReactJS, Node JS/Golang/Python, C++/ Python/ Edge Devices/Mini OS, QA Auto/Manual, PCB & Industrial design",
        "shortDescription": "",
        "company": "ITRVN - Impact Technical Resources",
        "type": "internship",
        "link": "https://www.itrvn.com/internship-program",
        "registrationOpen": false,
        "countries": [countries.vietnam]
    },
    {
        "title": "Summer internship program",
        "shortDescription": "",
        "company": "Orange Egypt",
        "type": "internship",
        "link": "https://www.orange.eg/en/about/careers/summer-internship",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software engineers, Devops engineers, QA engineers, Business Developers",
        "shortDescription": "",
        "company": "eSpace",
        "type": "internship",
        "link": "https://espace.com.eg/blog/announcing-espace-summer-internship-2024/",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Frontend/backend developers, QA/Testing engineers",
        "shortDescription": "",
        "company": "FlairsTech",
        "type": "internship",
        "link": "https://join.flairstech.com/",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Arbisoft's Summer Internship",
        "shortDescription": "",
        "company": "Arbisoft",
        "type": "internship",
        "link": "https://arbisoft.hirestream.io/careers/jobs/?search=intern",
        "registrationOpen": true,
        "countries": [countries.egypt]
    },
    {
        "title": "Summer Internship Program (Fullstack developers [.Net/Angular], etc.)",
        "shortDescription": "",
        "company": "ArabDT",
        "type": "internship",
        "link": "https://arabdt.com/career/internship-openings/",
        "registrationOpen": true,
        "countries": [countries.egypt]
    },
    {
        "title": "Customer service, Business analyst, Product owner, account manager, Odoo implementer, QA, IT Sales, etc.",
        "shortDescription": "",
        "company": "Inova",
        "type": "internship",
        "link": "https://inovaeg.com/jobs/",
        "registrationOpen": true,
        "countries": [countries.egypt]
    },

    {
        "title": "PHP/Drupal developer",
        "shortDescription": "",
        "company": "Vardot",
        "type": "internship",
        "link": "https://www.facebook.com/vardotters/photos/our-internship-program-is-accepting-fresh-grads-in-cairo-looking-to-work-and-lea/1887258857989805/",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Java FullStack developer",
        "shortDescription": "",
        "company": "DMS Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/dms-egypt_dont-miss-our-internship-program-bs-activity-7160924885802745856-d9To/",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Backend Software Development, iOS Software Development, Android Software Development, Software Quality Control, Business Analyst, UI/UX Designer",
        "shortDescription": "",
        "company": "AreebTechnology",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/dinadibrahim_areebtechnology-egypt-internship-activity-7117120265846689792-Y0EA",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software Testing Internship Program",
        "shortDescription": "",
        "company": "Unifonic Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/menna-abdelmotaleb-7264b1124_unifonic-software-testing-intern-activity-7206573366617583616-EmX6",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software developers and Software Testers",
        "shortDescription": "",
        "company": "Codescalers Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-internship2024-activity-7194901043254611968-hHJG",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software developers and Software Testers",
        "shortDescription": "",
        "company": "Codescalers Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-internship2024-activity-7194901043254611968-hHJG",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Embedded software development & testing, Software applications, model-based design, Artificial Intelligence, and so much more.",
        "shortDescription": "",
        "company": "Valeo",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/islammuhamed_copied-activity-7183411008286183424-E-ho",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Summer Internship Program 2024 (Digitization team, Software team)",
        "shortDescription": "",
        "company": "Advansys",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/advansys-esc_advansys-10abryears-training-activity-7211325938184175616-iHnG",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Embedded Systems Assistant Instructor Intern",
        "shortDescription": "",
        "company": "TEC",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/techie-egy-class_tec-is-offering-an-opportunity-to-join-our-activity-7156712226064642049-mgRo",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software Quality Control Internship",
        "shortDescription": "",
        "company": "Watan First Digital - Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/dinadibrahim_internship-watanabrfirstabrdigitalabregypt-activity-7170666856993820672-8Akn",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software development internship program (Java and Database)",
        "shortDescription": "",
        "company": "Rawafed Tech",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-activity-7168487337297887232-t3UD",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Embedded software development",
        "shortDescription": "",
        "company": "Fuzetek",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/fuzetek_embeddedsystems-computerscience-internship-activity-7145430935717535744-VAFu",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software Quality / Testing - Software Development",
        "shortDescription": "",
        "company": "Pharos Solutions",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-internship2024-activity-7196950111384408065-iHPT",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Backend developer (PHP)",
        "shortDescription": "",
        "company": "Jumia Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ahmed-hamdy-408b21237_backend-internship-activity-7193252752116924416-u_9B",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Internship - .Net Developer",
        "shortDescription": "",
        "company": "Watan First Digital Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/sofia-sobhy-8006861a3_dotnetjobs-dotnetdevelopers-dotnetdevelopment-activity-7170505116087791616-rDHF",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Software development and database programming",
        "shortDescription": "",
        "company": "Siemens Egypt",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-activity-7228254507866980352-KFjW",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Back-End Software Engineer Summer Internship (Ruby/PHP)",
        "shortDescription": "",
        "company": "Inova EG",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-internship2024-activity-7192764401639186432-o2Th",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Siemens Digital Industries Summer Internship for Software Engineers",
        "shortDescription": "",
        "company": "Siemens",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ayaosama05_copied-internship2024-activity-7192764401639186432-o2Th",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },
    {
        "title": "Business Analyst, UI/UX Product Designer, .Net Backend Developer, Frontend Developer [Angular Framework], Software Tester-QA/QC Engineer, Mobile/Flutter Developer",
        "shortDescription": "",
        "company": "Mega Tech",
        "type": "internship",
        "link": "https://www.linkedin.com/posts/ahmednoaman_copied-activity-7206692834190540802-39N4",
        "registrationOpen": false,
        "countries": [countries.egypt]
    },


    {
        "title": "",
        "shortDescription": "",
        "company": "",
        "type": "",
        "link": "",
        "registrationOpen": false,
        "countries": []
    },

] 
