import { useEffect } from 'react';
import { AbstractPage } from './abstractPage';
import HtmlHeader from '../components/html-header';


const MainPage = ({pageTitle, header}) => {

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (<AbstractPage >
    <HtmlHeader text={header} type="h1"/>
    <div>
        main page...
    </div>
  </AbstractPage>);
}

export default MainPage;