import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import "./index.css";

const Footer = () => {

  const intl = useIntl();

  return (<div className="footer">

    <div className="footer__container">
      <div className="footer__links">
        <Link className="footer__link" to="/about" rel="noreferrer">{intl.formatMessage({ id: "app.common.about" })}</Link>
        {/* <Link className="footer__link" to="/blog" rel="noreferrer">Блог</Link> */}
        {/* <a className="footer__link" target="_blank" href="https://www.instagram.com/devpulse.by/" rel="noreferrer">Наш инстаграм</a> */}
      </div>
    </div>

  </div >);
}

export default Footer;