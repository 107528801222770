import {
  Link
} from "react-router-dom";
import Menu from '../menu';
import './index.css';

const Navbar = () => {

  return (
    <div className='navbar' >
      <div className="navbar-container">
        <div className="navbar-container__logo-container">
          <Link to="/">
            <div className="navbar-container__logo">
              {/* <Logo className="navbar-container__logo-icon" /> */}
              <div className="navbar-container__logo-text">
                DEVSTART.XYZ
              </div>
            </div>
          </Link>
        </div>
        <div className="navbar-container__menu">
          <Menu />
        </div>
      </div>
    </div>);
}

export default Navbar;