import React from 'react';
import { useIntl } from 'react-intl';
import './index.css';

const Courses = ({items, lastUpdated}) => {
  const intl = useIntl();

  if (!items) {
    return (
      <div className="container">
        {intl.formatMessage({ id: "app.common.coursesLoadingMessage" })}
      </div>
    )
  }

  const formattedDate = intl.formatDate(lastUpdated, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const typeToLocale = {
    "course": intl.formatMessage({ id: "app.common.coursesCourseType" }),
    "internship": intl.formatMessage({ id: "app.common.coursesInternshipType" }),
    "bootcamp": intl.formatMessage({ id: "app.common.coursesBootcampType" })
  }

  return (
    <div>
      <p>🔥{intl.formatMessage({ id: "app.common.coursesFireHint" })}</p>
      <div className="publications container" >
        <div className='publications__info'>
          <div>{intl.formatMessage({ id: "app.common.coursesNumber" })}: {items.length}</div>
          <div>{intl.formatMessage({ id: "app.common.coursesUpdated" })}: {formattedDate}</div>
        </div>
        {
          items && items.length && items.length !== 0 ? items.map((item, index) => {
            const { title, shortDescription, link, company, type, registrationOpen } = item;
            return (<div key={index} >
              <div  className="publication">
                <div className='publication__title'>
                  {registrationOpen && <span>🔥 </span>}
                  {title} {type && `(${typeToLocale[type]})`}
                </div>
                <div>{shortDescription}</div>
                <div>{company}</div>
                <div className='publication__link'><a href={link + "?utm_source=devstart.xyz"} >{link}</a></div>
              </div>
              {/* <hr className='publication__hr' /> */}
            </div>)
          }) : (<div className="publication">
            {intl.formatMessage({ id: "app.common.coursesNotFound" })}
          </div>)
        }
      </div>
    </div>
  );
}

export default Courses;