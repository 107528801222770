import { useEffect } from 'react';

export const AbstractPage = (props) => {
  const { children, classNm } = props;
  useEffect(() => {
    // be at the top of the page when open it
    window.scrollTo(0, 0);
  }, []);

  return (<div className={classNm || "main-container"}>
    {children}
  </div>)

}